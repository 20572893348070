// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//require('@types/jquery.validation');
require("moment/locale/ja")
require("tempusdominus-bootstrap-4")
// require('jquery-ui');
require("jquery-ui-dist/jquery-ui");
require('../jquery.validate.min');

require('../jquery.time-to.min');

require('../subscription_checkout_button.js');


require("chartkick")
require("chart.js")
global.autosize = require("autosize")

//global.$ = require('jquery')
global.toastr = require("toastr")





// global.Calendar = require("@fullcalendar/core")
// global.interactionPlugin = require("@fullcalendar/interaction")
// global.dayGridPlugin = require("@fullcalendar/daygrid")
// global.timeGridPlugin = require("@fullcalendar/timegrid")
// global.listPlugin = require("@fullcalendar/list")
// global.bootstrapPlugin = require("@fullcalendar/bootstrap")



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import resourceTimelineCommonPlugin from '@fullcalendar/resource-common';

import resourceTimelinePlugin from '@fullcalendar/resource-timeline';



global.FullCalendar = Calendar;
global.interactionPlugin = interactionPlugin;
global.dayGridPlugin = dayGridPlugin;
global.timeGridPlugin = timeGridPlugin;
global.listPlugin = listPlugin;
global.bootstrapPlugin = bootstrapPlugin;
global.resourceTimelinePlugin = resourceTimelinePlugin;
global.resourceTimelineCommonPlugin = resourceTimelineCommonPlugin;



import TuiCalendar from 'tui-calendar'; /* ES6 */
global.TuiCalendar = TuiCalendar;
import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';


import 'bootstrap'
import '../stylesheets/application'
import "@fortawesome/fontawesome-free/js/all"
import $ from 'jquery';
import 'select2';                       // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';  // optional if you have css loader




// import { Calendar } from 'fullcalendar/main';


//import FullCalendar from 'fullcalendar';

// import  FullCalendar from 'fullcalendar';


//import "@types/jquery.validation"
// window.jQuery = $;
//global.$ = require('jquery')


document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
})

$(function() {
    $('[data-toggle="popover"]').popover();
});


 jQuery.validator.addMethod("lettersdigithyphenunderscoreonly", function(value, element) {
          return this.optional(element) || /^[a-zA-Z0-9_.-]+$/i.test(value);
      }, "Only characters, hyphen, underscore and numbers"); 



 jQuery.validator.addMethod("lettersdigithyphenunderscoreonlyspace", function(value, element) {
          return this.optional(element) || /^[a-zA-Z0-9_.-\s]+$/i.test(value);
      }, "Only characters, hyphen, underscore and numbers"); 


// $(function() {
//     $( "select" ).select2();
// });

